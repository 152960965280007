.ql-editor {
  min-height: 300px;
}

.iconbtn {
  width: 40px;
  height: 40px;
  padding: 0 !important;
  padding-left: 4px !important;
  margin: 0 5px !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* width */
.modal::-webkit-scrollbar {
  width: 5px;
  background: #f1f1f1;
}

/* Track */
.modal::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.modal::-webkit-scrollbar-thumb {
  background: #c1c1c1;
}

/* Handle on hover */
.modal::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.custom-label {
  position: relative;
  top: 28px;
  color: #aaaaaa !important;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.42857;
  letter-spacing: unset;
}

td .number-col {
  float: right;
}

.edit-number {
  width: 100px;
}

.edit-unitPrice {
  width: 100px !important;
}

.edit-qty {
  width: 50px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}